import React, {useState, useRef} from 'react';
import JoditEditor from "jodit-react";
import NotificationAlert from "react-notification-alert";
import axios from '../../axios'
import { useHistory } from 'react-router-dom';



const NewServicio = () => {
	const history = useHistory();
	const notificationAlert = useRef()
	const [servicio,setServicio] = useState({nombre:'',titulo:'',descripcion_corta:'',descripcion_larga:'',evento_social_corporativo:'0'})
	const config = {
		readonly: false,
		toolbar: true,
		askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
	}
	const _saveServicio = (e) =>{
		e.preventDefault();
		axios.post("api/servicios",servicio).then(res=>{
			//notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>El Servicio ha sido guardado exitosamente</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
			setTimeout(() => {
				history.push("/admin/servicios")
			}, 3000);

		}).catch(e=>{
			console.log("Error al guardar",e)
		})
	
	}

    return (
        <div className="content">
			<NotificationAlert ref={notificationAlert} />
            <div className='row'>
				<div className='col-12'>
					<div className="card">
						<div className="card-body">
							<form onSubmit={_saveServicio}>
								<div className ="row">
									<div className='col-6'>
										<label htmlFor="nombre_servicio">Nombre del servicio</label>
										<input type="text" id="nombre_servicio" className="form-control" placeholder="Nombre" 
											onChange={(e)=>setServicio({...servicio,nombre:e.target.value})}/>
									</div>
									<div className='col-6'>
										<label htmlFor="titulo">Frase</label>
										<input type="text" id="titulo" className="form-control" placeholder="Frase" 
											onChange={(e)=>setServicio({...servicio,titulo:e.target.value})} />
									</div>
									<div className='col-6'>
										<label htmlFor="titulo">Categoria de servicio</label>
										<select className="form-control" id="service_cat" onChange={(e)=>setServicio({...servicio,evento_social_corporativo:e.target.value})}>
											<option value="0">Servicio</option>
											<option value="1">Evento social o corporativo</option>
										</select>	
									</div>
									<div className='col-12 pt-4'>
										<label htmlFor="descripcion_corta">Descripción Corta</label>
    									<textarea className="form-control" id="descripcion_corta" rows="5" onChange={(e)=>setServicio({...servicio,descripcion_corta:e.target.value})}></textarea>
									</div>
									<div className='col-12 pt-4'>
										<label htmlFor="descripcion_larga">Descripción Larga</label>
										<JoditEditor
										value={servicio.descripcion_larga}
										config={config}		  
										tabIndex={1} // tabIndex of textarea
										onBlur={newContent =>setServicio({...servicio,descripcion_larga:newContent})} // preferred to use only this option to update the content for performance reasons
										/>
									</div>
								
									<div className='col-12 mt-4'>
										<button type="submit" className="btn btn-primary btn-block">Guardar</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
            </div>
        </div>
    );
};

export default NewServicio;