import React, { useRef,useContext } from 'react';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";
import { UserContext } from 'UserContext';

const MyProfile = () => {
    const {user,setUser} = useContext(UserContext);
    const notificationAlert = useRef();
  
    const _updateImg = (file) =>{
        const imgData = new FormData();
        imgData.append("image", file); 

        axios.post(`api/profile-img`,imgData)
        .then((res)=>{
            if(res.status === 200)
            setUser({...user,image:res.data})
        })
        .catch((e)=>console.log("error",e))
    }

    const saveUser = (e) =>{
        e.preventDefault()
        axios.put('api/user-update',user).then(res=>{
        }).catch(e=>console.log(e))
    }

    return (
        <div className='content'>
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
				<div className='col-12'>
					<div className="card p-4">
                        <div className='row'>
                            <div className='m-2' style={{height:180,position:'relative'}}>
                                <img style={{maxHeight:180}}
                                src={user.image ? user.image:require("assets/img/default_user.png").default} 
                                className="rounded mx-auto" alt="..." />
                                <label className='btn btn-primary btn-icon btn-round' style={{position:'absolute',right:5,color:'#FFF'}}>
                                    <i className="fa fa-pencil-alt"></i>
                                    <input type="file"  style={{display:'none'}} onChange={(e)=>_updateImg(e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
                                </label> 
                            </div>
                        </div>
                      
						<form onSubmit={saveUser}>
                            <div className ="row">
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="name">Nombre</label>
                                    <input type="text" id="name" className="form-control" placeholder="Nombre" value={user.name} 
                                        onChange={(e)=>setUser({...user,name:e.target.value})}/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="frase">Frase</label>
                                    <input type="text" id="frase" className="form-control" placeholder="Frase" value={user.phrase}
                                        onChange={(e)=>setUser({...user,phrase:e.target.value})}/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="name">Email</label>
                                    <input type="text" id="email" className="form-control" value={user.email} disabled/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id="password" className="form-control" value={user.email} disabled/>
                                </div>
                                <div className='col-12 mt-4'>
									<button type="submit" className="btn btn-primary btn-block" >Guardar</button>
								</div>
                               
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;