import React, {useRef, useState} from 'react';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";
import { useHistory } from 'react-router';

const NewUser = () => {
    const [data,saveData] = useState({name:'',email:'',password:'',password_confirmation:''})
    const [diferent,setDiferent] = useState(false)
    const history = useHistory();
	const notificationAlert = useRef()
    
    const _saveuser = (e) =>{
		e.preventDefault();
        axios.post('api/register',data).then(res=>{
			//notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Usuario creado exitosamente!!</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
			setTimeout(() => {
				history.push("/")
			}, 3000);
        }).catch(e=>console.log(e))

    }
    const _passwordHandler = (value) =>{
         setDiferent((data.password !== data.password_confirmation)? true:false)
    }

    return (
        <div className='content'>
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
				<div className='col-12'>
					<div className="card p-4">
						<form onSubmit={_saveuser}>
                            <div className ="row">
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="name">Nombre</label>
                                    <input type="text" id="name" className="form-control" placeholder="Nombre" 
                                        onChange={(e)=>saveData({...data,name:e.target.value})}/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="email">E-mail</label>
                                    <input type="email" id="email" className="form-control" placeholder="Email" 
                                        onChange={(e)=>saveData({...data,email:e.target.value})}/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="password">password</label>
                                    <input type="password" id="password" className="form-control" placeholder="Password" 
                                        onChange={(e)=>saveData({...data,password:e.target.value})}/>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label htmlFor="password_confirmation">Password Confirmación</label>
                                    <input type="password" id="password_confirmation" className="form-control" placeholder="Password Confimacion" 
                                        onChange={(e)=>saveData({...data,password_confirmation:e.target.value})}
                                        onBlur={(e)=>_passwordHandler(e.target.value)} />
                                    {diferent&& 
                                    <div className='text-danger'>Las contraseñas no coinciden</div>}
                                </div>
                                <div className='col-12 mt-4'>
									<button type="submit" className="btn btn-primary btn-block" disabled={diferent}>Guardar</button>
								</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUser;