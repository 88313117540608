/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{useEffect,useContext, useState, useRef} from "react";
import axios from "../axios";
import { UserContext } from "UserContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

function User() {
  const [users,setUsers] = useState([])
  const { user } = useContext(UserContext)
  const notificationAlert = useRef()

  useEffect(()=>{
    axios.get('api/users').then(res=>{
      setUsers(res.data)
    }).catch(e=>console.log(e))
  },[])

  const _deleteAdmin = (id)=>{
	if(user.master === 1){
		if(window.confirm('Estas seguro de eliminar a este administrador?')){
			axios.post('api/users',{id:id}).then(res=>{
				if(res.status === 200){
					notificationAlert.current.notificationAlert({
						place: "tr",
						message: (res.data.message),
						type: "success",
						icon: "nc-icon nc-satisfied",
						autoDismiss: 3,
					})
					setUsers(users.filter((u)=>u.id!==res.data.user.id))
				}
			}).catch(e=>alert("Error",e))
		}
	}else{
		alert('No tiene permiso para realizar esta accion')
	}
	
  }

  return (
    <>
      <div className="content">
	  <NotificationAlert ref={notificationAlert} />
        <Row>
			<Col md="12">
				<Card className="card-user">
				<div className="image" style={{width:"100%"}}>
					<img alt="..." src={require("assets/img/damir-bosnjak.jpg").default} />
				</div>
				<CardBody>
					<div className="author">
						<a href="#pablo" onClick={(e) => e.preventDefault()}>
							<img
							alt="..."
							className="avatar border-gray"
							src={user.image?user.image:require("assets/img/default-avatar.png").default}
							/>
							<h5 className="title">{user.name}</h5>
						</a>
						<p className="description">Administrador del sitio</p>
					</div>
					<p className="description text-center">
						{user.phrase}
					</p>
				</CardBody>
				</Card>
				<Card>
				<CardHeader>
					<Row>
					<CardTitle className="col-12 col-md-9" tag="h4">Miembros del equipo</CardTitle>
					<div className="col-12 col-md-3">
						<Link className="btn btn-primary float-right" to="usuario/new" role="button">
						<i className="fas fa-users"></i> Nuevo Administrador 
						</Link>
					</div>
					</Row>
				</CardHeader>
				<CardBody>
					<ul className="list-unstyled team-members">
					{users.filter((f)=>f.id!==user.id).map((item)=>
					<li key={item.id}>
						<Row>
							<Col md="1" xs="12">
								<div className="avatar">
								<img
									alt="..."
									className="img-circle img-no-padding img-responsive"
									src={require("assets/img/default_user.png").default}
								/>
								</div>
							</Col>
							<Col md="4" xs="12">
								{item.name} <br />
								<span className="text-muted">
								<small>Offline</small>
								</span>
							</Col>
							<Col md="3" xs="8">
								<i className="fa fa-envelope text-info"/> {item.email} 
							</Col>
							{user.master&&
								<Col className="text-right" md="3" xs="4">
									<Button
									className="btn-round btn-icon"
									color="danger"
									outline
									size="sm"
									onClick={(e)=>_deleteAdmin(item.id)}
									>
									<i className="fa fa-trash" />
									</Button>
								</Col>
							}
							
						</Row>
					</li>
					)}
					</ul>
				</CardBody>
				</Card>
			</Col>
        </Row>
      </div>
    </>
  );
}

export default User;
