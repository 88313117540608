import React, { useEffect, useState,useRef } from 'react';
import axios from '../../axios';
import { useParams } from 'react-router';
import FileUploadWithPreview from "file-upload-with-preview";
import NotificationAlert from "react-notification-alert";

const DetailServicio = () => {
    const {id} = useParams();
    const [servicio,setServicio] = useState({});
    const [imagenes,setImagenes] = useState([])
    const notificationAlert = useRef()

    useEffect(()=>{
        new FileUploadWithPreview("myUniqueUploadId",{maxFileCount:1});
        axios.get(`api/servicios/${id}`)
        .then(res=>{
            setServicio(res.data)
            setImagenes(res.data.galerias)
        }).catch(e=>console.log("Error",e))
    },[id])

    const _updateImg = (file,field) =>{
        const imgData = new FormData();
        imgData.append('field',field)
        imgData.append("file", file); 

        axios.post(`api/upload-servimg/${servicio.id}`,imgData)
        .then((res)=>{
            if(res.status === 200 && field==='banner') setServicio({...servicio,banner:res.data[field]})
            if(res.status === 200 && field==='img_1') setServicio({...servicio,img_1:res.data[field]})
            if(res.status === 200 && field==='img_2') setServicio({...servicio,img_2:res.data[field]})
        })
        .catch((e)=>console.log("error",e))
    }

    const _pushImgGal = (file) =>{
        const formData = new FormData()
        formData.append('servicio_id', id)
        formData.append('file', file)
        axios.post('api/servicios-galeria',formData).then((r) => {
            setImagenes([...imagenes, r.data])
            //notificacion
            notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Imagen Guardada Exitosamente !</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
            //new FileUploadWithPreview('myUniqueUploadId',{maxFileCount:1})
		})
		.catch(r=>alert(r))
    }

    const _deleteImg = (image_id) =>{
        if(window.confirm("¿Estas Seguro de borrar esta foto, si la borras no la podras recuperar?")){  
            axios.delete(`api/servicios-galeria/${image_id}`).then((res)=>{
                let type,icon,message =""

                if(res !==undefined && res.status === 200){
                    setImagenes(imagenes.filter((img)=>img.id!==res.data.galeria.id))
                    type = "success"
                    icon ="nc-icon nc-satisfied"
                    message = "Imagen borrada"
                }else{
                    type = "danger"
                    icon = "nc-icon nc-bell-55"
                    message = "No se pudo eliminar la foto"
                }   

                notificationAlert.current.notificationAlert({
                    place: "tr",
                    message: (message),
                    type: type,
                    icon: icon,
                    autoDismiss: 3,
                })
            })
        .catch((e)=>console.log("error",e))
        }
    }

    return (
        <div className="content">
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
                <div className='col-12'>
                    <div className="card">
                        <div className="card-body">
                            <h5>Imagenes para - {servicio.nombre}</h5>
                            <div className='row'>
                                <div className='col-12 col-md-2'>
                                    <p className='font-weight-bold'>Da click en el icono para subir el banner principal</p>
                                    <label style={{cursor:'pointer'}}>
                                        <i className="fas fa-image fa-10x"></i>
                                        <input type="file"  style={{display:'none'}} 
                                            onChange={(e)=>_updateImg(e.target.files[0],'banner')} 
                                            accept="image/png, image/gif, image/jpeg" />
                                    </label>
                                    <p className='text-info'>Nota: subir imagen en formato horizontal,<strong> si subes una imagen nueva se sobresscribe la anterior</strong></p>

                                </div>
                                <div className='col-md-4 centrar'>
                                    <label>Descripcion banner (SEO)</label>
                                    <input type="text" className='form-control mb-2'></input>
                                    {servicio.banner?
                                    <img src={servicio.banner} alt="" style={{height:300}}/>:
                                    <div className="alert alert-warning mt-4" role="alert">
                                        No has subido ninguna imagen 
                                    </div>
                                    }  
                                </div>

                                <div className='col-12 col-md-2'>
                                    <p className='font-weight-bold'>Da click en el icono para subir la img vertical</p>
                                    <label style={{cursor:'pointer'}}>
                                        <i className="fas fa-image fa-10x"></i>
                                        <input type="file"  style={{display:'none'}} 
                                            onChange={(e)=>_updateImg(e.target.files[0],'img_1')} 
                                            accept="image/png, image/gif, image/jpeg" />
                                    </label>
                                    <p className='text-info'>Nota: subir imagen en formato vertical,<strong> si subes una imagen nueva se sobresscribe la anterior</strong></p>

                                </div>
                                <div className='col-md-4 centrar'>
                                    <label>Descripcion Imagen vertical (SEO)</label>
                                    <input type="text" className='form-control mb-2'></input>
                                    {servicio.img_1?
                                    <img src={servicio.img_1} alt="" style={{height:300}}/>:
                                    <div className="alert alert-warning mt-4" role="alert">
                                        No has subido ninguna imagen 
                                    </div>
                                    }  
                                </div>

                                <div className='col-12 col-md-2'>
                                    <p className='font-weight-bold'>Da click en el icono para subir la img horizontal</p>
                                    <label style={{cursor:'pointer'}}>
                                        <i className="fas fa-image fa-10x"></i>
                                        <input type="file"  style={{display:'none'}} 
                                            onChange={(e)=>_updateImg(e.target.files[0],'img_2')} 
                                            accept="image/png, image/gif, image/jpeg" />
                                    </label>
                                    <p className='text-info'>Nota: subir imagen en formato vertical,<strong> si subes una imagen nueva se sobresscribe la anterior</strong></p>

                                </div>
                                <div className='col-md-4 centrar'>
                                    <label>Descripcion Imagen vertical (SEO)</label>
                                    <input type="text" className='form-control mb-2'></input>
                                    {servicio.img_2 !== '' ?
                                    <img src={servicio.img_2} alt="" style={{height:300}}/>:
                                    <div className="alert alert-warning mt-4" role="alert">
                                        No has subido ninguna imagen 
                                    </div>
                                    }  
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <div className="card">
                        <div className="card-body">
                            <h5>Galeria</h5>
                            <p className='text-danger ml-4 p-2 col-md-12'>Nota: Tamaño recomendado JPG 1920 x 1080/ 1024x728</p>
                            <div className='custom-file-container col col-md-12' data-upload-id='myUniqueUploadId' style={{marginBottom:50}}>
                                <label className='pl-3' style={{display:'none'}}>
                                    <a href='#!' className='custom-file-container__image-clear btn btn-primary text-decoration-none'  title='Limpiar'>Limpiar foto
                                    </a>
                                </label>
                                <label className='custom-file-container__custom-file col'>
                                    <span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:200, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:25,padding:15}}>
                                        <input onChange={(e)=>_pushImgGal(e.target.files[0])} type='file' className='custom-file-container__custom-file__custom-file-input' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*' multiple required/>
                                        Arrastra tu imagen aqui..
                                    </span>
                                    <input type='hidden' name='MAX_FILE_SIZE' value='10485760' />
                                    <span className='custom-file-container__custom-file__custom-file-control'></span>
                                </label>
                                <div className='custom-file-container__image-preview' style={{display:'none'}}></div>
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                {imagenes.length === 0 ?
                                    <p className='text-danger h6 text-center col'>No tienes imagenes guardadas</p>:
                                    imagenes.map((image)=>
                                        <div className='col col-md-3' key={image.id} >
                                            <div className="card " style={{padding:0}}>
                                                <img src={image.image} className="card-img-top" alt="..." />
                                                <label className='btn btn-danger btn-icon btn-round' onClick={(e)=>_deleteImg(image.id)}  style={{position:'absolute',right:10,color:'#FFF'}}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </label>
                                            </div>
                                        </div>
                                )}  
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailServicio;