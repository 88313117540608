/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import Maps from "views/Map.js";
import UserPage from "views/User.js";
import Servicios from "views/Servicio/Servicios";
import NewServicio from "views/Servicio/NewServicio";
import EditServicio from "views/Servicio/EditServicio";
import Configs from "views/Configs/Configs";
import Carousel from "views/Configs/Carousel";
import General from "views/Configs/General";
import About from "views/Configs/About";
import Casa from "views/Configs/Casa";
import DetailServicio from "views/Servicio/DetailServicio";
import Frases from "views/Frases/Frases";
import NewFrase from "views/Frases/NewFrase";
import EditFrase from "views/Frases/EditFrase";
import NewUser from "views/User/NewUser";
import Aviso from "views/Configs/Aviso";
import MyProfile from "views/User/MyProfile";

/*
  la propiedad type define el tipo de ruta y si esta aparecera en el menu lateral del admin
  -link = mostrara un nuevo modulo en el menu
  -subroute = rutas internas no accesibles desde el menu Ej. Nuevo item, editar item etc..
*/
var routes = [
  {
    path: "/user-page",
    name: "Usuarios",
    type:"link",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/usuario/new",
    name: "Nuevo Usuario",
    type:"subroute",
    icon: null,
    component: NewUser,
    layout: "/admin",
  },
  {
    path: "/mi-perfil",
    name: "Nuevo Usuario",
    type:"subroute",
    icon: null,
    component:MyProfile,
    layout: "/admin",
  },
  {
    path: "/servicios",
    name: "Servicios",
    type:"link",
    icon: "nc-icon nc-istanbul",
    component: Servicios,
    layout: "/admin",
  },
  {
    path: "/servicios/new",
    name: "Nuevo Servicio",
    type:"subroute",
    icon: null,
    component: NewServicio,
    layout: "/admin",
  },
  {
    path: "/servicios/edit/:id",
    name: "Editar Servicio",
    type:"subroute",
    icon: null,
    component: EditServicio,
    layout: "/admin",
  },
  {
    path: "/servicios/detail/:id",
    name: "Detalle del servicio",
    type:"subroute",
    icon: null,
    component: DetailServicio,
    layout: "/admin",
  },
  {
    path: "/phrases",
    name: "Notas de amor",
    type:"link",
    icon: "nc-icon nc-favourite-28",
    component: Frases,
    layout: "/admin",
  },
  {
    path: "/phrases/new",
    name: "Nueva nota",
    type:"subroute",
    icon: null,
    component: NewFrase,
    layout: "/admin",
  },
  {
    path: "/phrases/edit/:id",
    name: "Editar Frase",
    type:"subroute",
    icon: null,
    component: EditFrase,
    layout: "/admin",
  },
  {
    path: "/configs",
    name: "Configuraciones",
    type:"link",
    icon: "nc-icon nc-settings-gear-65",
    component: Configs,
    layout: "/admin",
  },
  {
    path: "/configs/carrusel",
    name: "Configuraciones",
    type:"subroute",
    icon: null,
    component: Carousel,
    layout: "/admin",
  },
  {
    path: "/configs/general",
    name: "Configuraciones",
    type:"subroute",
    icon: null,
    component: General,
    layout: "/admin",
  },
  {
    path: "/configs/about-us",
    name: "Configuraciones",
    type:"subroute",
    icon: null,
    component: About,
    layout: "/admin",
  },
  {
    path: "/configs/casa",
    name: "Configuraciones",
    type:"subroute",
    icon: null,
    component: Casa,
    layout: "/admin",
  },
  {
    path: "/configs/aviso",
    name: "Aviso de Privacidad",
    type:"subroute",
    icon: null,
    component: Aviso,
    layout: "/admin",
  },
 /* {
    path: "/dashboard",
    name: "Dashboard",
    type:"link",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    type:"link",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    type:"link",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    type:"link",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    type:"link",
    icon: "nc-icon nc-caps-small",
    component: Typography,
    layout: "/admin",
  },*/
  {
    pro: true,
    path: "/login",
    name: "Cerrar Sesión",
    type:"action",
    icon: "nc-icon nc-user-run",
    component: null,
    layout: "/admin",
  },
];




export default routes;
