import React ,{useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
  } from "reactstrap";

const Servicios = () => {
    const [servicios,setServicios] = useState([]);
    const notificationAlert = useRef();
    
	useEffect(()=>{
		axios.get('api/servicios').then(res=>{
			setServicios(res.data)
		}).catch(e=>console.log(e))
	},[])

	const _changeStatus = (e,id) =>{
		//cambiamos el estado del swtich
		setServicios(servicios.map(obj => {
			if (obj.id === id){
				return {...obj, active:e.target.checked}
			} return obj
		}))
		axios.post('api/set-active',{'id':id,'active':e.target.checked})
		.then((resp)=>{
			//notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Se ha actualizado el estatus exitosamente</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
		}).catch((e)=>console.log("error",e))
	}

	const _delteServicio = (id) =>{
		if(window.confirm("¿Estas seguro de borrar este servicio?")){
            axios.delete(`api/servicios/${id}`).then(res=>{
                if(res.status === 200){
					notificationAlert.current.notificationAlert({
						place: "tr",
						message: (<div>Se ha borrado elservicio exitosamente</div>),
						type: "success",
						icon: "nc-icon nc-satisfied",
						autoDismiss: 3,
					})
					setServicios(servicios.filter((s)=>s.id!==res.data.id))
                }
            }).catch(e=>console.log(e))
        }
	}

    return (
        <>
			<div className="content">
			<NotificationAlert ref={notificationAlert} />
				<Row>
					<Col md="12">
						<Link className="btn btn-primary float-right" to="servicios/new" role="button">
							Nuevo Evento 
						</Link>
					</Col>
					<Col md="12">
					<Card>
					<CardHeader>
						<CardTitle tag="h4">Simple Table</CardTitle>
					</CardHeader>
					<CardBody>
						<Table responsive>
						<thead className="text-primary">
							<tr>
							<th>ID</th>
							<th>Nombre</th>
							<th>Titulo</th>
							<th>Categoria</th>
							<th>Estatus</th>
							<th className="text-right">Acciones</th>
							</tr>
						</thead>
						<tbody>
							{servicios.map((servicio)=>
								<tr key={servicio.id}>
									<td>{servicio.id}</td>
									<td>{servicio.nombre}</td>
									<td>{servicio.titulo}</td>
									<td>{(servicio.evento_social_corporativo == 0) ? "Servicio":"Evento Social | Corporativo"}</td>
									<td>
										<div className="custom-control custom-switch">
											<input type="checkbox" className="custom-control-input" id={'customSwitch'+servicio.id} 
											checked={servicio.active}
											onChange={(e)=>{_changeStatus(e,servicio.id)}}
											/>
											<label className="custom-control-label" style={{cursor:'pointer'}}  htmlFor={'customSwitch'+servicio.id}>Off/On</label>
										</div>
									</td>
									<td className="text-right">
										<Link to={`servicios/edit/${servicio.id}`} className="btn btn-info btn-fab btn-icon btn-round">
											<i className="fa fa-pencil-alt"></i>
										</Link>&nbsp;
										<Link to={`servicios/detail/${servicio.id}`} className="btn btn-primary btn-fab btn-icon btn-round">
											<i className="fa fa-images"></i>
										</Link>&nbsp;
										<button className="btn btn-danger btn-fab btn-icon btn-round" onClick={(e)=>_delteServicio(servicio.id)}>
											<i className="far fa-trash-alt"></i>
										</button>
									</td>
							</tr>
							)}
						</tbody>
						</Table>
					</CardBody>
					</Card>
					</Col>
				
				</Row>
			</div>
		</>
    );
};

export default Servicios;