import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";
const Configs = () => {
    return (
       
        <div className="content">
            <Row>
                <Col md="4" lg="3">
                    <Link to="configs/carrusel" >
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-album-2 text-warning config-card-icon" />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">Carrusel</CardTitle>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Agregar Imagenes al carrusel</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>
                <Col md="4" lg="3">
                    <Link to="configs/general" >
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-settings text-warning " />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">General</CardTitle>
                                    </Col>
                                  
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Configuracion general del sitio</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>
               {/*<Col md="4" lg="3">
                    <Link to="configs/general" >
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-email-85 text-warning" />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">Email</CardTitle>
                                    </Col>
                                  
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Configuaciones de email del sitio</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>*/}
                <Col md="4" lg="3">
                    <Link to="configs/about-us">
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-paper text-warning" />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">About Us</CardTitle>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Configuaciones de la seccion de nosotros</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>
                <Col md="4" lg="3">
                    <Link to="configs/casa">
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-bank text-warning" />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">Casa</CardTitle>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Configuaciones de la seccion de casa</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>
                <Col md="4" lg="3">
                    <Link to="configs/aviso">
                        <Card className="card-stats config-card">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-single-copy-04 text-warning" />
                                        </div>
                                        <CardTitle className="text-center" tag="h3">Aviso de Privacidad</CardTitle>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className='text-center'>Configuaciones del aviso de privacidad</div>  
                            </CardFooter>
                        </Card>
                    </Link>
                </Col>
            </Row> 
        </div>
    );
};

export default Configs;