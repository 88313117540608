import React,{useState,useEffect,useRef} from 'react';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const Casa = () => {
    const [config,setConfig] = useState({home_frace:'',home_text:'',home_img:''});
    const notificationAlert = useRef()

    useEffect(()=>{
        axios.get('api/config/1')
        .then((res)=>{
            setConfig(res.data)
        })
        .catch((e)=>console.log("error",e))
    },[])

    const _updateCasa = (e) =>{
        e.preventDefault()
        const {id,home_frace,home_text} = {...config}
		const conf = {id,home_frace,home_text}
       
        axios.put('api/savecasa',conf).then((res)=>{
            notificationAlert.current.notificationAlert({
                place: "tr",
                message: (<div>Datos Guardados exitosamente !</div>),
                type: "success",
                icon: "nc-icon nc-satisfied",
                autoDismiss: 3,
            })
        }).catch((e)=>console.log("error",e))
    }

    const _updateImg = (file) =>{

        const imgData = new FormData();
        imgData.append('field','home_img')
        imgData.append("file", file); 

        axios.post(`api/saveimg/${config.id}`,imgData)
        .then((res)=>{
            if(res.status === 200){
                setConfig({...config,home_img:res.data.home_img})
                //notificacion
                notificationAlert.current.notificationAlert({
                    place: "tr",
                    message: (<div>Datos Guardados exitosamente !</div>),
                    type: "success",
                    icon: "nc-icon nc-satisfied",
                    autoDismiss: 3,
                })
            }
        })
        .catch((e)=>console.log("error",e))
    }

    return (
        <div className="content">
         <NotificationAlert ref={notificationAlert} />
            <div className='card p-3'>
                <h5>Agrega tus textos para la sección de Casa</h5>
                <form onSubmit={_updateCasa}>
                    <div className='row'>

                        <div className="form-group col-md-12">
                            <label htmlFor="descripcion">Descripcion casa</label>
                            <textarea className="form-control" id="descripcion" placeholder="La descripcion de casa..." rows="5"
                            value={config.home_text}
                            onChange={(e)=>setConfig({...config,home_text:e.target.value})}
                            ></textarea>
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="descripcion">Frase casa</label>
                            <textarea className="form-control" id="frase" placeholder="La frase de casa..." rows="15"
                            value={config.home_frace}
                            onChange={(e)=>setConfig({...config,home_frace:e.target.value})}
                            ></textarea>
                        </div>
                        <div className='col-md-12 text-right'>
                            <button type="submit" className="btn btn-primary">Actualizar datos</button>
                        </div>
                    </div>  
                </form>
            </div>
            <div className='card col-md-12'>
                <div className='row p-2'>
                    <h4 className='col-md-12'>Configura la imagen</h4>
                    <div className='col-md-4'>
                        <p className='font-weight-bold'>Da click para subir tu img</p>
                        <p className='text-warning'>Nota: subir imagen en formato horizontal, si subes una imagen nueva se sobresscribe la anterior</p>
                        <label style={{cursor:'pointer'}}>
                            <i className="fas fa-image fa-10x"></i>
                            <input type="file"  style={{display:'none'}} 
                                onChange={(e)=>_updateImg(e.target.files[0])} 
                                accept="image/png, image/gif, image/jpeg" />
                        </label>
                    </div>
                    <div className='col-md-8 centrar'>
                        {config.home_img?
                            <img src={config.home_img} alt="Casa" style={{height:300}}/>:
                            <div className="alert alert-warning" role="alert">
                                No has subido ninguna imagen 
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Casa;