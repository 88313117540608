import Loader from 'components/Loader/Loader';
import React, {useState, useContext,useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {FormGroup,Label,Input} from "reactstrap";
import { UserContext } from 'UserContext';
import  axios from '../../axios'


const Login = () => {
    const [data, setData] = useState({email:"",password:""})
    const { setUser, user, auth, setAuth }= useContext(UserContext);
    const [loader,setLoader] = useState(false)
    const history = useHistory();

    useEffect(()=>{
        setLoader(true)
        axios.get('/api/user').then(response=>{
            setUser(response.data)
            setAuth(true)
            setLoader(false)
            history.push("/admin/user-page") 
        }).catch(e=>{
            console.log("error",e)
            setLoader(false)
        })
    },[])
     
    const _login = (e) =>{
        e.preventDefault()
        setLoader(true)
        axios.post('api/login',data).then((res)=>{
            setUser(res.data)
            setAuth(true)
            localStorage.setItem('access_token', 'Bearer '+res.data.access_token);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.access_token
            setLoader(false)
            history.push("/admin/user-page")
        }).catch(e=>console.log("error",e))
    }

    return (
        loader?
        <Loader></Loader>
        :
        <div style={{backgroundImage:'url(/login-background.jpg)'}}>
            <div className='container' style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <div className='card col col-md-8 p-4' style={{ background:'rgba(255,255,255,.5)'}}>
                <img src="/logo_corazon.png" class="rounded mx-auto d-block" style={{height:180}} alt="..." />
                <form onSubmit={_login}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" style={{color:'black'}}>Email</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                            placeholder="Escribe tu e-mail" 
                            onChange={(e)=>setData({...data,email:e.target.value})}
                        />
                        <small id="emailHelp"  style={{color:'black'}} className="form-text">No comprartas tu contraseña con nadie más.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1" style={{color:'black'}}>Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                            onChange={(e)=>setData({...data,password:e.target.value})}
                        />
                    </div>
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" />{' '}
                        <span style={{color:'black'}}>Recuerdame</span>
                        <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                        </Label>
                    </FormGroup>
                    <button type="submit" className="btn btn-primary float-right col-sm-12 col-md-3">Iniciar Sesión</button>
                </form>
            </div>       
            </div>
        </div>
    );
};

export default Login;