import React,{useContext,useEffect,useState} from 'react';
import {Route,Redirect} from 'react-router';
import { UserContext } from 'UserContext';
import Loader from 'components/Loader/Loader';
import axios from './axios';

const PrivateRoute = ({ component:Component = null, render: Render = null , ...rest })=>{
    const { auth,setAuth,user,setUser }= useContext(UserContext);
    const [loader,setLoader] = useState(true)
   // const history = useHistory();
   
    useEffect(()=>{
        axios.get('/api/user').then(response=>{
            setUser(response.data)
            setAuth(true)
            setLoader(false)
        }).catch(e=>{
            console.log("error",e)
            setLoader(false)
        })
    },[setAuth,setUser])

   if(!loader){
        return (
        <Route
            {...rest}
            render={props =>
            auth? (
                Render ? (
                    Render(props)
                ) : Component ? (
                    <Component {...props} />
                ) : null
                ): (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
            }
        />
        
        );
    }
    else return <Loader></Loader>
};

export default PrivateRoute;