import React, {useState, useEffect, useRef } from 'react';
import JoditEditor from "jodit-react";
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const About = () => {
    const [config,setConfig] = useState({about_us:'',mission:'',vission:'',about_frase:'',about_img:''});
    const notificationAlert = useRef()
    const conf = {
		readonly: false,
		toolbar: true,
		askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
	}
    useEffect(()=>{
      
        axios.get('api/config/1')
        .then((res)=>{
            setConfig(res.data)
        })
        .catch((e)=>console.log("error",e))
    },[])

    const _updateAbout = (e) =>{
        e.preventDefault();
        const {id,about_us,mission,vission,about_frase} = {...config}
		const data = {id,about_us,mission,vission,about_frase}

        axios.put('api/editabouts',data).then((res)=>{
            //notificacion
            setConfig(res.data);
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Datos Guardados exitosamente !</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
        }).catch(e=>console.log(e))
    }    

    const _updateImg = (file) =>{

        const imgData = new FormData();
        imgData.append('field','about_img')
        imgData.append("file", file); 

        axios.post(`api/saveimg/${config.id}`,imgData)
        .then((res)=>{
            if(res.status === 200) setConfig({...config,about_img:res.data.about_img})
        })
        .catch((e)=>console.log("error",e))
    }
    
    return (
        <div className='content'>
             <div className='card p-4'>
                <h5>Acerca de nosotros</h5>
                <NotificationAlert ref={notificationAlert} />
                <form onSubmit={_updateAbout}>
                    <div className='row'>
                    
                        <div className='col col-md-6'>
                            <label>Nosotros</label>
                                <JoditEditor
                                value={config.about_us}
                                config={conf}		  
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent =>setConfig({...config,about_us:newContent })} // preferred to use only this option to update the content for performance reasons
                                />
                        </div>
                        <div className='col col-md-6'>
                            <label >Misión</label>
                                <JoditEditor
                                value={config.mission}
                                config={conf}		  
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent =>setConfig({...config,mission:newContent })} // preferred to use only this option to update the content for performance reasons
                                />
                        </div>
                        <div className='col col-md-6 mt-4'>
                            <label >Visión</label>
                                <JoditEditor
                                value={config.vission}
                                config={conf}		  
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent =>setConfig({...config,vission:newContent })} // preferred to use only this option to update the content for performance reasons
                                />
                        </div>
                        <div className='col-sm-12 col-md-6 mt-4'>
                            <label>Frase</label>
                                <textarea className='form-control'style={{maxHeight:200,height:200}}
                                value={config.about_frase} 
                                onChange={(e)=>setConfig({...config,about_frase:e.target.value })}></textarea>
                        </div>
                        <div className='col-md-12 text-right mt-3'>
                            <button type="submit" className="btn btn-primary">Guarda datos</button>
                        </div>
                    </div>
                </form>
                <div className='card col-md-12'>
                    <div className='row p-2'>
                        <h4 className='col-md-12'>Configura la imagen</h4>
                        <div className='col-md-4'>
                            <p className='font-weight-bold'>Da click para subir tu img</p>
                            <p className='text-warning'>Nota: subir imagen en formato horizontal, si subes una imagen nueva se sobre-escribe la anterior</p>
                            <label style={{cursor:'pointer'}}>
                                <i class="fas fa-image fa-10x"></i>
                                <input type="file"  style={{display:'none'}} 
                                    onChange={(e)=>_updateImg(e.target.files[0])} 
                                    accept="image/png, image/gif, image/jpeg" />
                            </label>
                        </div>
                        <div className='col-md-8 centrar'>
                            {config.about_img?
                                <img src={config.about_img} alt="Casa" style={{height:300}}/>:
                                <div class="alert alert-warning" role="alert">
                                    No has subido ninguna imagen 
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;