import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const EditFrase = () => {
    const {id} = useParams();
    const [phrase, setPhrase] = useState({title:'',phrase:'',image:null,imageFile:null})
    const history = useHistory()
	const notificationAlert = useRef()

    useEffect(()=>{
        axios.get(`api/phrases/${id}`)
        .then((res)=>{
            setPhrase(res.data)
        }).catch(e=>console.log(e))
    },[])

    const _updatePhrase = (e) =>{
        e.preventDefault()
        const data = new FormData();
        data.append('title',phrase.title)
        data.append('phrase',phrase.phrase)
        if(phrase.imageFile) {
            data.append("file", phrase.imageFile)
        }

       axios.post(`api/phrases/${id}`,data)
        .then((res)=>{
            //notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>La Frase ha sido actualizada exitosamente</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
			setTimeout(() => {
				history.push("/admin/phrases")
			}, 3000);
        }).catch(e=>console.log(e))
    }
    return (
        <div className='content'>
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className="card-body">
                            <form onSubmit={_updatePhrase}>
								<div className ="row">
									<div className='col-12'>
										<label htmlFor="titulo">Titulo</label>
										<input type="text" id="titulo" className="form-control"  
                                            value={phrase.title}
											onChange={(e)=>setPhrase({...phrase,title:e.target.value})} />
									</div>
					
									<div className='col-12'>
                                        <label htmlFor="descripcion">Frase</label>
                                        <textarea className="form-control" id="descripcion" style={{maxHeight:150,height:150}}
                                        value={phrase.phrase}
                                        onChange={(e)=>setPhrase({...phrase,phrase:e.target.value})}
                                        ></textarea>
									</div>
                                    <div className='col-12 col-md-4 p-4'>
                                        <p className='font-weight-bold'>Da click para cambiar tu img</p>
                                    
                                        <label style={{cursor:'pointer'}}>
                                            <i className="fas fa-image fa-10x"></i>
                                            <input type="file"  style={{display:'none'}} 
                                                onChange={(e)=>setPhrase({...phrase,imageFile:e.target.files[0],image:URL.createObjectURL(e.target.files[0])})}
                                                accept="image/png, image/gif, image/jpeg" />
                                        </label>
                                    </div>
                                    <div className='col-12 col-md-8 centrar mt-2'>
                                        {phrase.image?
                                            <img src={phrase.image} alt="Casa" style={{height:300}}/>:
                                            <div className="alert alert-warning" role="alert">
                                                No has subido ninguna imagen 
                                            </div>
                                        }
                                    </div>
								
									<div className='col-12 mt-4'>
										<button type="submit" className="btn btn-primary btn-block">Guardar</button>
									</div>
								</div>
							</form>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default EditFrase;