import React, { useEffect, useState, useRef} from 'react';
import FileUploadWithPreview from "file-upload-with-preview";
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";
import { useHistory } from 'react-router-dom';

const NewFrase = () => {
    const [phrase, setPhrase] = useState({title:'',phrase:'',image:null})
    const history = useHistory()
	const notificationAlert = useRef()
   
    useEffect(()=>{
        new FileUploadWithPreview("myUniqueUploadId",{maxFileCount:1})
    },[])
   
    const _savePhrase = (e) =>{
        e.preventDefault()
        const data = new FormData();
        data.append('title',phrase.title)
        data.append('phrase',phrase.phrase)
        data.append("file", phrase.image); 
        axios.post('/api/phrases',data).then(response=>{
            //notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>La Frase ha sido guardada exitosamente</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
			setTimeout(() => {
				history.push("/admin/phrases")
			}, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <div className='content'>
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className="card-body">
							<form onSubmit={_savePhrase}>
								<div className ="row">
						
									<div className='col-12'>
										<label htmlFor="titulo">Titulo</label>
										<input type="text" id="titulo" className="form-control"  
											onChange={(e)=>setPhrase({...phrase,title:e.target.value})} />
									</div>
					
									<div className='col-12'>
                                        <label htmlFor="descripcion">Frase</label>
                                        <textarea className="form-control" id="descripcion"  rows="15"
                                        onChange={(e)=>setPhrase({...phrase,phrase:e.target.value})}
                                        ></textarea>
									</div>
                                    <div className='col-12 p-4'>
                                        <div className='custom-file-container col col-md-12' data-upload-id='myUniqueUploadId'>
                                            <label className='pl-3'>
                                                <button className='custom-file-container__image-clear btn btn-primary text-decoration-none'  title='Limpiar'>Limpiar foto
                                                </button>
                                            </label>
                                            <label className='custom-file-container__custom-file col'>
                                                <span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:200, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:25,padding:15}}>
                                                    <input onChange={(e)=>setPhrase({...phrase,image:e.target.files[0]})} type='file' className='custom-file-container__custom-file__custom-file-input' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*' required/>
                                                    Arrastra tu imagen aqui..
                                                </span>
                                                <input type='hidden' name='MAX_FILE_SIZE' value='10485760' />
                                                <span className='custom-file-container__custom-file__custom-file-control'></span>
                                            </label>
                                            
                                            <div className='custom-file-container__image-preview p-4' 
                                            style={{height:'150px',backgroundRepeat:'no-repeat',backgroundSize:'contain',display:(phrase.image)?'':'none'}}></div>
                                            
                                        </div>
                                    </div>
								
									<div className='col-12 mt-4'>
										<button type="submit" className="btn btn-primary btn-block">Guardar</button>
									</div>
								</div>
							</form>
						</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewFrase;