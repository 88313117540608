/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import { NavLink,useHistory } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import axios from '../../axios';
import logo from "logo.svg";

var ps;


function Sidebar(props) {
  const history = useHistory();
  const sidebar = React.useRef();
  
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const logout = () =>{
    axios.get('/api/logout')
		.then(r=>{
			console.log(r.data)
      history.push("/")
		})
  }

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo row">
        <div className="col-12">
            <div className="logo-img d-flex align-items-center justify-content-center">
              <img src="/logo_blanco.png" alt="react-logo" style={{maxHeight:70}}/>
            </div>
        </div>
      <div className="col-12 text-center">
        <a
            href="https://corazondeorohechoenmexico.com.mx"
            className="simple-text logo-normal"
          >
            Corazón de Oro
          </a>
      </div>
       
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              	prop.type !=="subroute"?
					<li className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} key={key}>
          {
						(prop.type === "link")?
						<NavLink
							to={prop.layout + prop.path}
							className="nav-link"
							activeClassName="active"
						>
							<i className={prop.icon} />
							<p>{prop.name}</p>
						</NavLink>:
							<a className="nav-link" style={{bottom:10}}  onClick={logout}>
							  <i className={prop.icon} />
							  <p>{prop.name}</p>
							</a>
					}
              		</li>:null
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
