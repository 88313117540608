import { data } from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import axios from '../../axios';

const Frases = () => {
    const [frases,setFrases] = useState([])
    useEffect(()=>{
        axios.get('api/phrases')
        .then(resp=>{
            setFrases(resp.data)
        }).catch(e=>console.log("error ",e))
    },[])

    const _deletePhrase = (id) =>{
        if(window.confirm("¿Estas seguro de borrar esta frase?")){
            axios.delete(`api/phrases/${id}`).then(res=>{
                if(res.status === 200){
                    setFrases(frases.filter((item)=> item.id !== id))
                }
            }).catch(e=>console.log(e))
        }
    }

    return (
        <div className='content'>
           <div className='row'>
                <div className='col-12'>
                    <Link className="btn btn-primary float-right" to="phrases/new" role="button">
                        Nuevo Nota 
                    </Link>
                </div>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>ID</th>
                                        <th>Imagen</th>
                                        <th>Titulo</th>
                                        <th>Frase</th>
                                        <th className="text-right">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {frases.map((item)=>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>
                                                <img className="rounded-circle" alt="100x100" src={item.image} data-holder-rendered="true" style={{height:55,width:55}}/>
                                            </td>
                                            <td>{item.title}</td>
                                            <td>
                                                <div className='d-inline-block text-truncate' style={{width:500}}>{item.phrase }</div>
                                            </td>
                                        
                                            <td className="text-right"> 
                                                <Link to={`phrases/edit/${item.id}`} className="btn btn-info btn-fab btn-icon btn-round">
                                                    <i className="fa fa-pencil-alt"></i>
                                                </Link>&nbsp;
                                            
                                                <button className="btn btn-danger btn-fab btn-icon btn-round" onClick={(e)=>_deletePhrase(item.id)}>
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                               
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default Frases;