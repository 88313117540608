import React, { useState,useEffect, useRef } from 'react';
import JoditEditor from "jodit-react";
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const Aviso = () => {
    const [config,setConfig] = useState({aviso_privacidad:''});
    const notificationAlert = useRef()
    const conf = {
		readonly: false,
		toolbar: true,
		askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
	}

    useEffect(()=>{
        axios.get('api/config/1')
        .then((res)=>{
            setConfig(res.data)
        })
        .catch((e)=>console.log("error",e))
    },[])

    const _updateAviso = (e) =>{
        e.preventDefault()
        axios.put('api/save-aviso',config)
        .then((res)=>{
            setConfig(res.data)
            notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Datos Guardados exitosamente !</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
        }).catch(e=>console.log(e))
    }

    return (
        <div className='content'>
            <NotificationAlert ref={notificationAlert} />
            <div className='row'>
                <div className='card col-md-12 m-2 p-2'>
                    <form onSubmit={_updateAviso}>
                        <div className='row'>
                            <div className='col col-md-12'>
                                <h5 className='mt-2 p-2'>Aviso de Privacidad</h5>
                                    <JoditEditor className="mb-4"
                                    value={config.aviso_privacidad}
                                    config={conf}		  
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent =>setConfig({...config,aviso_privacidad:newContent })} // preferred to use only this option to update the content for performance reasons
                                    />
                            </div>
                            <div className='col-md-12 text-right mt-3'>
                                <button type="submit" className="btn btn-primary">Guarda</button>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Aviso;