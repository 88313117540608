import React, {useEffect,useState,useRef} from 'react';
import FileUploadWithPreview from "file-upload-with-preview";
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const Carousel = () => {
    const [imagenes,setImagenes] = useState([])
    const notificationAlert = useRef()

    useEffect(()=>{
        new FileUploadWithPreview("myUniqueUploadId",{maxFileCount:1});
        axios.get('/api/carousels').then((r)=>{
            setImagenes(r.data)
        }).catch((e)=>console.log("Error",e))
    },[])
    //Funcion para agregar una imagen nueva al carrusel
    const _pushImg = (file) =>{
        const formData = new FormData()
        formData.append('file', file)
        axios.post('/api/carousels',formData).then((r) => {
            setImagenes([...imagenes, r.data])
            //notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Imagen Guardada Exitosamente !</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
            new FileUploadWithPreview('myUniqueUploadId',{maxFileCount:1})
		})
		.catch(r=>alert(r))
    }
    //Funcion para editar los datos de texto de la imagen
    const _updateImgData = (e,obj) =>{
        e.preventDefault();
        axios.put(`api/carousels/${obj.id}`,obj).then((r)=>{
            setImagenes(imagenes.map((img)=>
                (img.id === r.data.id) ? r.data:img
            ))
            notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Datos actualizados!</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
        }).catch(e=>console.log("error al actualizar data",e))
    }

    const _updateImg= (id,img) =>{
        const fData = new FormData();
        fData.append('id', id)
		fData.append("file", img);
		axios.post(`api/updatecarouselimg/${id}`,fData)
		.then(r=>{
            setImagenes(imagenes.map((img)=>
                (img.id === r.data.id) ? {...img,image:r.data.image}:img
            ))
		})
		.catch(r=>alert(r))
    }

    const _deleteImg = (id) =>{
        if(window.confirm("¿Estas Seguro de borrar esta foto, si la borras no la podras recuperar?")){
            axios.delete(`api/carousels/${id}`)
            .then((res)=>{
                let type,icon =""

                if(res !==undefined && res.status === 200){
                    setImagenes(imagenes.filter((img)=>img.id!==res.data.carousel.id))
                    type = "success"
                    icon ="nc-icon nc-satisfied"
                }else{
                    type = "danger"
                    icon = "nc-icon nc-bell-55"
                }   

                notificationAlert.current.notificationAlert({
                    place: "tr",
                    message: (<div>No se pudo eliminar la foto</div>),
                    type: type,
                    icon: icon,
                    autoDismiss: 3,
                })
            })
            .catch((e)=>console.log("error",e))
        }
    }

    return (
        <div className="content">
        <NotificationAlert ref={notificationAlert} />
            <div className='row'>
                <p className='text-danger ml-4 p-2 col-md-12'>Nota: Tamaño recomendado JPG 1200x600 / 1024x728</p>
                <div className='custom-file-container col col-md-12' data-upload-id='myUniqueUploadId' style={{marginBottom:50}}>
                    <label className='pl-3' style={{display:'none'}}>
                        <a href='#!' className='custom-file-container__image-clear btn btn-primary text-decoration-none'  title='Limpiar'>Limpiar foto
                        </a>
                    </label>
                    <label className='custom-file-container__custom-file col'>
                        <span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:200, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:25,padding:15}}>
                            <input onChange={(e)=>_pushImg(e.target.files[0])} type='file' className='custom-file-container__custom-file__custom-file-input' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*' multiple required/>
                            Arrastra tu imagen aqui..
                        </span>
                        <input type='hidden' name='MAX_FILE_SIZE' value='10485760' />
                        <span className='custom-file-container__custom-file__custom-file-control'></span>
                    </label>
                    <div className='custom-file-container__image-preview' style={{display:'none'}}></div>
                </div>
                <div className='col-12 row'>
                {imagenes.length === 0 ? 
                    <p className='text-danger h6 text-center col'>No tienes imagenes guardadas</p>
                    :
                    imagenes.map((image)=>
                    <div className='col col-md-4' key={image.id} >
                        <div className="card " style={{padding:0}}>
                            <img src={image.image} className="card-img-top" alt="..." />
                            <label className='btn btn-primary btn-icon btn-round' style={{position:'absolute',right:10,color:'#FFF'}}>
                                <i className="fa fa-pencil-alt"></i>
                                <input type="file"  style={{display:'none'}} onChange={(e)=>_updateImg(image.id,e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
                            </label>
                            <label className='btn btn-danger btn-icon btn-round' onClick={(e)=>_deleteImg(image.id)}  style={{position:'absolute',right:55,color:'#FFF'}}>
                                <i className="fas fa-trash-alt"></i>
                            </label>
                            
                            <div className="card-body">
                                <form onSubmit={(e)=>_updateImgData(e,image)}>
                                    <div className="form-group">
                                        {/*
                                        <label htmlFor="url_text">Titulo</label>
                                        <input type="text" className="form-control" id="url_text" 
                                            defaultValue={image.titulo}
                                            onChange={(e)=>(image.titulo=e.target.value)} />
                                      
                                        */}  
                                        <label htmlFor="description">Descripcion 1</label>
                                        <textarea className="form-control" id="description" rows="5"
                                            defaultValue={image.description}
                                            onChange={(e)=>(image.description=e.target.value)}
                                        ></textarea>
                                        <label htmlFor="description">Descripcion 2</label>
                                        <textarea className="form-control" id="description" rows="5"
                                            defaultValue={image.description_2}
                                            onChange={(e)=>(image.description_2=e.target.value)}
                                        ></textarea>
                                        <label htmlFor="description">Descripcion 3</label>
                                        <textarea className="form-control" id="description" rows="5"
                                            defaultValue={image.description_3}
                                            onChange={(e)=>(image.description_3=e.target.value)}
                                        ></textarea>
                                        {/*
                                        <label htmlFor="url_text">Texto del botón</label>
                                        <input type="text" className="form-control" id="url_text"  
                                            defaultValue={image.url_text}
                                            onChange={(e)=>(image.url_text=e.target.value)} />
                                        <label htmlFor="url">Url</label>
                                        <input type="text" className="form-control" id="url"
                                            defaultValue={image.url}
                                            onChange={(e)=>(image.url=e.target.value)} />
                                        */}
                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary">Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>  
                    )}
                </div>
            </div>

        </div>
    );
};

export default Carousel;