import React,{useEffect,useRef,useState,Fragment} from 'react';
import JoditEditor from "jodit-react";
import NotificationAlert from "react-notification-alert";
import axios from '../../axios'
import { useHistory, useParams} from 'react-router-dom';


const EditServicio = () => {
    const {id} = useParams();
    const [servicio,setServicio] = useState({nombre:'',titulo:'',descripcion_corta:'',descripcion_larga:''})
    const history = useHistory();
    const notificationAlert = useRef();
    const config = {
		readonly: false,
		toolbar: true,
		askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
	}

    useEffect(()=>{
        axios.get(`api/servicios/${id}`)
        .then(res=>{
            setServicio(res.data)
        }).catch(e=>console.log("Error",e))
    },[id])

    const _editServicio = (e) =>{
		e.preventDefault();
		axios.put(`api/servicios/${servicio.id}`,servicio)
		.then((res)=>{
			//notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Se ha actualizado el servicio exitosamente</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
			setTimeout(() => {
				history.push("/admin/servicios")
			}, 3000);
		})
    }
    return (
        <div className="content">
			<NotificationAlert ref={notificationAlert} />
            <div className='row'>
				<div className='col-12'>
					<div className="card">
						<div className="card-body">
							<form onSubmit={_editServicio}>
								<div className ="row">
									<div className='col-12 col-md-6'>
										<label htmlFor="nombre_servicio">Nombre del servicio</label>
										<input type="text" id="nombre_servicio" className="form-control" placeholder="Nombre"
                                            value={servicio.nombre}
											onChange={(e)=>setServicio({...servicio,nombre:e.target.value})}/>
									</div>
									<div className='col-12 col-md-6'>
										<label htmlFor="titulo">Frase</label>
										<input type="text" id="titulo" className="form-control" placeholder="Frase"
                                            value={servicio.titulo}
											onChange={(e)=>setServicio({...servicio,titulo:e.target.value})} />
									</div>
									<div className='col-12 col-md-6 mt-2'>
										<label htmlFor="titulo">Categoria de servicio</label>
										<select className="form-control" id="service_cat" 
											value={servicio.evento_social_corporativo}
											onChange={(e)=>setServicio({...servicio,evento_social_corporativo:e.target.value})}>
											<option value="0">Servicio</option>
											<option value="1">Evento social o corporativo</option>
										</select>	
									</div>
									{servicio.evento_social_corporativo == 1 ?
									<Fragment>
										<div className='col-6 col-md-3 mt-2'>
											<label htmlFor="back-color">Color de Background</label>
											<input type="color" id="back-color" className="form-control form-colorpick" 
												value={servicio.service_background_color}
												onChange={(e)=>setServicio({...servicio,service_background_color:e.target.value})} />
										</div>
										<div className='col-6 col-md-3 mt-2'>
											<label htmlFor="text-color">Color de los textos</label>
											<input type="color" id="text-color" className="form-control form-colorpick" 
												value={servicio.service_background_text}
												onChange={(e)=>setServicio({...servicio,service_background_text:e.target.value})} />
										</div>
									</Fragment>:<></>
									}
									<div className='col-12 pt-4'>
										<label htmlFor="descripcion_corta">Descripción Corta</label>
    									<textarea className="form-control" id="descripcion_corta" rows="5" value={servicio.descripcion_corta} onChange={(e)=>setServicio({...servicio,descripcion_corta:e.target.value})}></textarea>
									</div>
									<div className='col-12 pt-4'>
										<label htmlFor="descripcion_larga">Descripción Larga</label>
										<JoditEditor
										value={servicio.descripcion_larga}
										config={config}		  
										tabIndex={1} // tabIndex of textarea
										onBlur={newContent =>setServicio({...servicio,descripcion_larga:newContent})} // preferred to use only this option to update the content for performance reasons
										/>
									</div>
									<div className='col-12 mt-4'>
										<button type="submit" className="btn btn-primary btn-block">Editar Servicio</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
            </div>
        </div>
    );
};

export default EditServicio;