import React ,{ useEffect,useState,useRef } from 'react';
import axios from '../../axios';
import NotificationAlert from "react-notification-alert";

const General = () => {
    const [config,setConfig] = useState({page_title:'',page_description:'',phone:'',phone_2:'',mail:'',mail_receiver:'',mail_receiver_2:'',url_insta:'',url_insta_2:''});
    const notificationAlert = useRef()

    useEffect(()=>{
        axios.get('api/config/1')
        .then((res)=>{
            setConfig(res.data)
        })
        .catch((e)=>console.log("error",e))
    },[])

    const _saveGeneralData = (e) =>{
        e.preventDefault();
        const {id,page_title,page_description,phone,phone_2,mail,mail_receiver,mail_receiver_2,url_insta,url_insta_2} = {...config}
		const conf = {id,page_title,page_description,phone,phone_2,mail,mail_receiver,mail_receiver_2,url_insta,url_insta_2}

        axios.put('api/editgeneral',conf).then((res)=>{
             //notificacion
			notificationAlert.current.notificationAlert({
				place: "tr",
				message: (<div>Datos Guardados exitosamente !</div>),
				type: "success",
				icon: "nc-icon nc-satisfied",
				autoDismiss: 3,
			})
        }).catch(e=>console.log(e))
    }

    const _updateLogo = (tipo,file) =>{

        let field = (tipo === "logo" ? 'logo':'logo_secundary')

        const imgData = new FormData();
        imgData.append('field',field)
        imgData.append("file", file); 

        axios.post(`api/store-logo/${config.id}`,imgData)
        .then((res)=>{
            if(field === 'logo' && res.status === 200) setConfig({...config,logo:res.data.image})
            if(field === 'logo_secundary' && res.status === 200) setConfig({...config,logo_secundary:res.data.image})
            
        })
        .catch((e)=>console.log("error",e))

    }

    return (
        <div className='content'>
            <div className='row'>
                <NotificationAlert ref={notificationAlert} />
                <div className='card col-md-12'>
                    <form onSubmit={_saveGeneralData} className='p-2'>
                        <div className='form-row'>
                            <h4 className='col-md-12'>Configuraciones Generales</h4>
                            <div className="form-group col-md-12">
                                <label htmlFor="titulo">Titulo de la pagina</label>
                                <input type="text" className="form-control" id="titulo" placeholder="Titulo" 
                                    value={config.page_title}
                                    onChange={(e)=>setConfig({...config,page_title:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="descripcion">Descripcion de la pagina</label>
                                <textarea className="form-control" id="descripcion" placeholder="La descripcion de tu pagina..." rows="5"
                                value={config.page_description}
                                onChange={(e)=>setConfig({...config,page_description:e.target.value})}
                                ></textarea>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="telefono">Telefono o Whatsapp</label>
                                <input type="text" className="form-control" id="telefono" placeholder="Ej.3322112211" 
                                value={config.phone}
                                onChange={(e)=>setConfig({...config,phone:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="telefono2">Telefono o Whatsapp secundario (opcional)</label>
                                <input type="text" className="form-control" id="telefono2" placeholder="Ej. 3342332233" 
                                    value={config.phone_2}
                                    onChange={(e)=>setConfig({...config,phone_2:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="insta">Instagram URL</label>
                                <input type="text" className="form-control" id="insta" placeholder="Ej. http://instagram.com/tumarca" 
                                    value={config.url_insta}
                                    onChange={(e)=>setConfig({...config,url_insta:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="insta2">Instagram URL 2</label>
                                <input type="text" className="form-control" id="insta2" placeholder="Ej. http://instagram.com/tumarca" 
                                    value={config.url_insta_2}
                                    onChange={(e)=>setConfig({...config,url_insta_2:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email de contacto</label>
                                <input type="email" className="form-control" id="email" placeholder="Email de contacto" 
                                    value={config.mail}
                                    onChange={(e)=>setConfig({...config,mail:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email de remitente 1</label>
                                <input type="email" className="form-control" id="email" placeholder="Email de contacto" 
                                    value={config.mail_receiver}
                                    onChange={(e)=>setConfig({...config,mail_receiver:e.target.value})}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="email">Email de remitente 2</label>
                                <input type="email" className="form-control" id="email" placeholder="Email de contacto" 
                                    value={config.mail_receiver_2}
                                    onChange={(e)=>setConfig({...config,mail_receiver_2:e.target.value})}
                                />
                            </div>

                            <div className='col-md-12 text-right'>
                                <button type="submit" className="btn btn-primary">Actualizar datos</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className='card col-md-12'>
                    <div className='row p-2'>
                        <h4 className='col-md-12'>Configura el logo de tu pagina</h4>
                        <div className='col-md-6'>
                            <p className='font-weight-bold'>Da click en la imagen para subir tu logo</p>
                            <label style={{cursor:'pointer'}}>
                                <i className="fas fa-image fa-10x"></i>
                                <input type="file"  style={{display:'none'}} 
                                    onChange={(e)=>_updateLogo('logo',e.target.files[0])} 
                                    accept="image/png, image/gif, image/jpeg" />
                            </label>
                        </div>
                        <div className='col-md-6'>
                            {config.logo&&
                                <img src={`${config.logo}?${new Date().getTime()}`} alt="logo" style={{height:150}}/>
                            }
                        </div>

                        <div className='col-md-6'>
                            <p className='font-weight-bold'>Da click en la imagen para subir tu logo secundario</p>
                            <label style={{cursor:'pointer'}}>
                                <i className="far fa-image fa-10x"></i>
                                <input type="file"  style={{display:'none'}} 
                                    onChange={(e)=>_updateLogo('logo_secundary',e.target.files[0])} 
                                    accept="image/png, image/gif, image/jpeg" />
                            </label>
                        </div>
                        <div className='col-md-6'>
                            {config.logo_secundary&&
                                <img src={`${config.logo_secundary}?${new Date().getTime()}`} alt="logo secundario" style={{height:150}}/>
                            }
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    );
};

export default General;