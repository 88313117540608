import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "assets/scss/admin.scss"
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "layouts/Admin.js";
import Login from 'components/Auth/Login';
import { UserProvider } from 'UserContext';
import PrivateRoute from 'PrivateRoute';


const App=()=>{	
  
	return (
        <UserProvider>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <Login></Login>
                    </Route>
                    <PrivateRoute  path="/admin" render={(props) => <AdminLayout {...props} />} />
                </Switch>
            </Router>
        </UserProvider>
	);
}

export default App;